import React from "react";

import AdSense from "react-adsense";
import { AD_CLIENT, AD_SLOT } from "../constant";
import { getHoursRound } from "../helpers/roundUtils";
import BaseLayout from "../layout/BaseLayout/BaseLayout";
import ArchiveListItem from "../components/ArchiveListItem";
import Pagination from "../components/Pagination";

const HistoryLegacyTemplate = ({ pageContext }) => {
  const { sessionItem, pagination } = pageContext;
  const filteredAvailable = sessionItem.data.filter(
    item => item.createdAt !== null
  );
  const date = new Date(sessionItem.updatedAt).toLocaleDateString("pl-PL");

  return (
    <BaseLayout
      header={`Dane historyczne z dnia: ${date}, sesja sprzedaży 
    ${getHoursRound(sessionItem.numOfRound)}`}
      description={`Poniżej znajdują się dane historyczne, które zostały zebrane podczas sesji sprzedaży o godz.
      ${getHoursRound(sessionItem.numOfRound)} na podstawie
    zarejestowanych czasów dostępności, prawidłowość danych jest
    zależna od obciążenia serwerów w danej sesji. Statystyki pozwalają
    ocenić szanse na zakup wybranego produktu w następnych sesjach.
    `}
    >
      <Pagination {...pagination} />

      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow md:rounded-lg">
                <div className="bg-white shadow overflow-hidden sm:rounded-md">
                  <ul role="list" className="divide-y divide-gray-200 ml-0">
                    {filteredAvailable.map(
                      (item: any, index: React.Key | null | undefined) => (
                        <React.Fragment key={index}>
                          {Boolean(Number(index) % 5 === 0) && index !== 0 && (
                            <AdSense.Google
                              client={AD_CLIENT}
                              slot={AD_SLOT}
                              style={{ display: "block" }}
                              format="auto"
                              responsive="true"
                              layoutKey="-gw-1+2a-9x+5c"
                            />
                          )}
                          <ArchiveListItem {...item} />
                        </React.Fragment>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Pagination {...pagination} />
    </BaseLayout>
  );
};

export default HistoryLegacyTemplate;
